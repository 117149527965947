import React, {useEffect} from "react";
import FormSelectionBox from "../../Components/Form_Selection_Box/Form_Selection_Box";
import "./Form_Selection_Page.css";

function FormSelectionPage() {

  const formSelectionData = [
    {
      boxName: "Customer",
      imageUrl:
        "/assets/person-icon.avif",
      navigateLink: "/customer-auth",
    },
    {
      boxName: "Channel Partner",
      imageUrl:
        "/assets/hand-shaking-image.png",
      navigateLink: "/channel-partner-auth",
    },
    {
      boxName: "Revisit",
      imageUrl:
        "/assets/revisit-image.png",
      navigateLink: "/revisit",
    },
    {
      boxName: "Event",
      imageUrl:
        "/assets/event.png",
      navigateLink: "/event",
    }
  ];




  return (
    <div className='formSelectionPageWrapper'>
      <div className='formSelectionBoxWrapper'>
        {formSelectionData.map((data) => {
          const { boxName, imageUrl, navigateLink, onClick } = data;
          return (
            <div key={boxName}>
              <FormSelectionBox
                boxName={boxName}
                imageUrl={imageUrl}
                navigateLink={navigateLink}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FormSelectionPage;
