import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Customer_Form.css";
import { toast } from "react-toastify";
import SearchInputComponent from "../../utils/SearchBar";
import RequiredField from "../RequiredField/RequiredField";

const CustomerForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [salesData, setSalesData] = useState('');
  const [existsCustomer,SetexitsCustomer]=useState([])
  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    meetingWith: "",
    lastFiveDigits: ""
  });
  
  const validateField = async (name, value) => {
    let errorMessage = "";

    // Clear previous errors for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the current field error
    }));

    // Field-specific validations
    if (name === "name" && !/^[A-Za-z\s]+$/.test(value)) {
      errorMessage = "Name should only contain alphabets and spaces.";
    } else if (name === "phoneNumber" && !/^[0-9]{5}$/.test(value)) {
      errorMessage = "Phone number must be exactly 5 digits.";
    } else if (name === "email" && value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      errorMessage = "Please enter a valid email address.";
    } else if (name === "lastFiveDigits" && !/^[0-9]{5}$/.test(value)) {
      errorMessage = "Last five digits must be exactly 5 digits.";
    } else if (name === "meetingWith" && !/^[A-Za-z\s]+$/.test(value)) {
      errorMessage = "Meeting with should only contain alphabets and spaces.";
    }

 

    if ((name === "phoneNumber") && value.length === 5) {
      let requestBody = { phoneNumber: value };

      try {
        let response = await fetch(
         "https://siteregistration.inframantra.com/api/v1/customer/validation",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        // console.log(response)

        response = await response.json()
        SetexitsCustomer(response.data)
        if (!response.ok) {
          SetexitsCustomer(response.data)
          // errorMessage = `Customer exists under this ${name}`;
        }
      } catch (err) {
        console.error("Validation API error:", err);
        errorMessage = "Error validating customer. Please try again.";
      }
    }
    if ((name === "lastFiveDigits") && value.length === 5) {
      let requestBody = { phoneNumber: value };

      try {
        let response = await fetch(
          "https://siteregistration.inframantra.com/api/v1/customer/validation",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        response = await response.json()
        SetexitsCustomer(response.data)
        // console.log(response)
        if (!response.ok) {
          // console.log(response.data)
         
          // errorMessage = `Customer exists under this ${name}`;
        }
      } catch (err) {
        console.error("Validation API error:", err);
        errorMessage = "Error validating customer. Please try again.";
      }
    }

    // Update the error state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  useEffect(() => {
    // Fetch data from API when component mounts
    fetchUserData();
  }, []);

  useEffect(()=>{

    if(formData?.phoneNumber<5 || formData?.lastFiveDigits<5){
      SetexitsCustomer(()=>[])
    }
  },[])

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/sales",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      setSalesData(data[0].names);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSalesData([]);
      toast.error(`Error: ${error.message}`);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    lastFiveDigits: "",
    fullPhoneNumber: "",
    email: "",
    address: "",
    city: "",
    pincode: "",
    occupationType: "",
    designation: "",
    companyName: "",
    meetingWith: "",
    placeOfVisit: "",
    channelPartnerName: "",
    cpEnrolled: "",
    cpPhoneNumber: "",
    cpCompanyName: "",
    cpName: "",
    priceRange: "",
    sizeRange: "",
    date: getCurrentDate(), // Set default value to current date
  });

  // Function to get the current date in the format YYYY-MM-DD
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const channelPartner = localStorage.getItem("channel-partner");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent selecting future dates
    if (name === "date") {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        // Reset the value to today's date
        setFormData((prevData) => ({
          ...prevData,
          date: getCurrentDate(),
        }));
        return; 
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if(((name === "phoneNumber") && value.length < 5)||((name === "lastFiveDigits") && value.length <5)){
      SetexitsCustomer(()=>[])
    }
    validateField(name, value);
  };

  const clearForm = () => {
    setFormData({
      name: "",
      phoneNumber: "",
      lastFiveDigits: "",
      email: "",
      address: "",
      state: "",
      pincode: "",
      occupationType: "",
      designation: "",
      companyName: "",
      meetingWith: "",
      placeOfVisit: "",
    });
  };

  const [cpCompanyName, setCpCompanyName] = useState("");
  const [selectedCpName, setSelectedCpName] = useState("");
  const [meetingWith, setMeetingWith] = useState("");

  // Handle the selected value for cpCompanyName
  const handleCpCompanyName = (val) => {
    setSelectedCpName(val);
  };

  // Handle the selected value for meetingWith
  const handleMeetingWith = (val) => {
    const str = val;
    const parts = str.split("-"); // Split the string into parts using the dash
    const result = parts[0]; // Concatenate the first part with " XYZ-" and the second part
    setMeetingWith(result);
  };

  const userName = localStorage.getItem("username");

  const handleCompanyName = async () => {
    setFormData((prevData) => ({
      ...prevData,
      ['phoneNumber']:'',
    }));
    setFormData((prevData) => ({
      ...prevData,
      ['lastFiveDigits']:'',
    }));
    SetexitsCustomer(()=>[])
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/allData/company-names",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Provide headers as an object
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;

      if (!response.ok) {
        throw new Error(`${message}`);
      }

      setCpCompanyName(() => data); // Update state with fetched data using a callback
    } catch (error) {
      setCpCompanyName("");
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if the "Other" option is selected
    if (formData.sizeRange === "other" && formData.otherSize.trim() === "") {
      toast.error("Please provide the size in the 'Other' field");
      return;
    }

    let apiUrl = "";
    let method = "";
    let requestBody = "";
    
    if (channelPartner) {
      apiUrl =   "https://siteregistration.inframantra.com/api/v1/channel-partner/customer";
      method = "POST";
      requestBody = {
        name: formData.name,
        phoneNumber: formData.fullPhoneNumber,
        placeOfVisit: formData.placeOfVisit,
        channelPartnerPhoneNumber: formData.fullPhoneNumber,
        lastFiveDigits: formData.phoneNumber,
        meetingWith: formData.meetingWith,
      };
    } else {
      requestBody = {};
      console.log(formData.phoneNumber,formData.lastFiveDigits)

      // Dynamically include phoneNumber or lastFiveDigits based on which is valid
      if (formData.phoneNumber && formData.phoneNumber.length === 5) {
        requestBody.phoneNumber = formData.phoneNumber;
        requestBody.lastFiveDigit = formData.phoneNumber;
        
      } else if (formData.lastFiveDigits && formData.lastFiveDigits.length === 5) {
        requestBody.lastFiveDigit = formData.lastFiveDigits;
        requestBody.phoneNumber = formData.lastFiveDigits;
      } else {
        toast.error("Please provide a valid 5-digit Phone Number or Last Five Digits.");
        return;
      }

      requestBody = {
        ...requestBody,
        name: formData.name,
        email: formData.email,
        address: formData.address,
        city: formData.city,
        pincode: formData.pincode,
        meetingWith: meetingWith,
        placeOfVisit: formData.placeOfVisit,
        cpEnrolled: selectedCpName,
        budget: formData.priceRange,
        requirement:
          formData.sizeRange === "other" ? formData.otherSize : formData.sizeRange,
        date: formData.date,
        createdBy: userName,
      };

      apiUrl =  "https://siteregistration.inframantra.com/api/v1/customer/register";
      method = "POST";
    }

    try {
      document.querySelector(".spinner").classList.add("show");

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`${responseData.message}`);
        setTimeout(() => {
          navigate("/form-selection");
          document.querySelector(".spinner").classList.remove("show");
        }, 2000);
        clearForm();
      } else {
        toast.error(`${responseData.message}`);
        document.querySelector(".spinner").classList.remove("show");
      }
    } catch (error) {
      console.error("Error sending form data:", error.message);
      document.querySelector(".spinner").classList.remove("show");
    }
  };

  return (
    <div className="customerFormWrapper">
      {channelPartner ? (
        <div className="section-head">
          <h2>Add Customer Details</h2>
        </div>
      ) : (
        <div className="section-head">
          <h2>Customer Details</h2>
        </div>
      )}
      <div className="form-container">
        <form onSubmit={handleSubmit}>
        <>
              <div >
                <label>
                  {" "}
                  <RequiredField />
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                 {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div>
                <label>
                  <RequiredField />
                  CP EnRolled
                </label>
                <select
                  name="cpEnrolled"
                  value={formData.cpEnrolled}
                  onChange={(e) => {
                    handleChange(e);
                    SetexitsCustomer(()=>[])
                    if (e.target.value === "Yes") {
                      handleCompanyName();
                    }
                  }}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              {formData.cpEnrolled === "Yes" && (
                <div>
                  <div>
                    <label>CP Company Name</label>
                    <SearchInputComponent
                      data={cpCompanyName}
                      selectedValue={(val) =>
                        handleCpCompanyName(val, "cpCompanyName")
                      }
                    />

                  </div>
                </div>
              )}
              {formData.cpEnrolled === "Yes" ? (
                ""
              ) : (
                <div>
                  <label>
                    <RequiredField /> Phone No:
                  </label>
                  <input
                    type="tel"
                    pattern="[0-9]{5}"
                    maxLength={5}
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                   {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                  
                </div>
              )}
               {formData.cpEnrolled === "Yes" ? (
                ""
              ):(
                <ul>
  {existsCustomer?.length > 0 ? (
    existsCustomer.map((customer) => (
      <li key={customer.id || customer.name}>
        {customer?.name} → {customer?.placeOfVisit}
      </li>
    ))
  ) : (
null
  )}
</ul>
              )
            }

         
              {formData.cpEnrolled === "Yes" && (
                <div>
                  
                  <label><RequiredField/>Last Five Digits:</label>
                  <input
                    type="tel"
                    name="lastFiveDigits"
                    pattern="[0-9]{5}"
                    maxLength={5}
                    autoComplete="off"
                    value={formData.lastFiveDigits}
                    onChange={handleChange}
                    required
                  />
                   {errors.lastFiveDigits && <span className="error">{errors.lastFiveDigits}</span>}
                  
                </div>
              )}
               {formData.cpEnrolled === "Yes" ? (
                <ul>
  {existsCustomer?.length > 0 ? (
    existsCustomer.map((customer) => (
      <li key={customer.id || customer.name}>
        {customer?.name} → {customer?.placeOfVisit}
      </li>
    ))
  ) : (
    null
  )}
</ul>
              
              ):(
               null
              )
            }
              {/* <div>
                <label>
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  autoComplete="off"
                />
                 {errors.email && <span className="error">{errors.email}</span>}
              </div> */}
              <div>
                <label>Requirement</label>
                <select
                  name="sizeRange"
                  value={formData.sizeRange}
                  onChange={handleChange}
                >
                  <option value="">Select Your Requirement (BHK)</option>
                  <option value="1 BHK">1 BHK</option>
                  <option value="2 BHK">2 BHK</option>
                  <option value="3 BHK">3 BHK</option>
                  <option value="4 BHK">4 BHK</option>
                  <option value="other">Others</option>
                </select>
              </div>
              {formData.sizeRange === "other" && (
                <div>
                  <label>Other Size</label>
                  <input
                    type="text"
                    name="otherSize"
                    value={formData.otherSize}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div>
                <label>
                  Remarks:
                </label>
                <textarea
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={100}
                  rows={5}
                  cols={5} // Define the number of columns
                  style={{ maxWidth: '600px', maxHeight: '100px' }} // Set maximum width and height
                />
              </div>
              <div>
                <label className="title">Budget</label>
                <select
                  name="priceRange"
                  value={formData.priceRange}
                  onChange={handleChange}
                >
                  <option value="">Please Select A Price</option>
                  <option value="Less than 1Cr*">Less than 1Cr*</option>
                  <option value="1Cr-1.5Cr">Rs. 1Cr* - 1.5 Cr*</option>
                  <option value="1.5Cr-2cr">Rs. 1.5 C* - 2Cr*</option>
                  <option value="2Cr-2.5Cr">Rs. 2 Cr* - 2.5 Cr*</option>
                  <option value="3Cr-3.5Cr">Rs. 3 Cr* - 3.5 Cr*</option>
                  <option value="4cr-4.5Cr">Rs. 4 Cr* - 4.5 Cr*</option>
                  <option value="4.5Cr-5Cr">Rs. 4.5 Cr* - 5 Cr*</option>
                  <option value="more than 5Cr">more than 5Cr*</option>
                </select>
              </div>
             
              {/* <div className="customerFormRow">
                <div>
                  <label>
                    Pincode:
                  </label>
                  <input
                    type="tel"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    autoComplete="off"
                    maxLength={6}
                  />
                   {errors.pincode && <span className="error">{errors.pincode}</span>}
                </div>
              </div> */}
              <div>
                <label>Meeting With:</label>
                <SearchInputComponent
                  name="meetingWith"
                  data={salesData}
                  selectedValue={(val) => handleMeetingWith(val)}
                />
                 {errors.meetingWith && <span className="error">{errors.meetingWith}</span>}
              </div>
              <div>
                <label>
                  Place of Visit:
                </label>
                <select
                  name="placeOfVisit"
                  value={formData.placeOfVisit}
                  onChange={handleChange}
                  // required
                >
                  <option value="">Select Place of Visit</option>
                  <option value="Inframantra HO">Inframantra HO</option>
                  <option value="Vatika Seven Elements">
                    Vatika Seven Elements
                  </option>
                  <option value="Sovereign Park">
                  Sovereign Park
                  </option>
                </select>
              </div>
              <div>
                <label>
                  Date:
                </label>
                <input
                  type="date"
                  name="date"
                  id=""
                  required
                  value={formData.date}
                  onChange={handleChange}
                  max={getCurrentDate()} // Set the max attribute to today's date
                />
              </div>
            </>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default CustomerForm;
