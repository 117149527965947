import React, { useState } from "react";
import axios from "axios";
import "./event_page.css";

const App = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    designation: "",
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Validation rules
  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "Name should contain alphabets only.";
        }
        break;

      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) {
          error = "Phone number must be exactly 10 digits.";
        }
        break;

      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;

      case "designation":
        if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "Designation should contain alphabets only.";
        }
        break;

      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    return error === "";
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value); // Validate field on change
  };

  // Validate all fields before submission
  const validateForm = () => {
    const fieldNames = Object.keys(formData);
    let isValid = true;

    fieldNames.forEach((field) => {
      const isFieldValid = validateField(field, formData[field]);
      if (!isFieldValid) isValid = false;
    });

    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (!validateForm()) {
      setMessage({ type: "error", text: "Please fix the errors above." });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post("https://siteregistration.inframantra.com/api/v1/event/create", {
        ...formData,
        createdBy: "Admin",
      });

      setMessage({ type: "success", text: response.data.message });
      setFormData({ name: "", phoneNumber: "", email: "", designation: "" }); // Clear form
      setErrors({}); // Clear errors
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "An error occurred. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
   
      <div className="content">
        <div className="profile">
          <img
            src="/assets/bk-shivani.jpg" // Replace with actual image URL
            alt="BK Shivani"
            className="profile-image"
          />
          <h3 className="name">BK Shivani</h3>
          <p className="designation">"Spiritual Guide"</p>
        </div>
        <div className="form-container">
          <h2 className="form-title">
            Let's Celebrate Your Child's Personality within the Womb
          </h2>
          {message && (
            <p
              className={message.type === "success" ? "success-message" : "error-message"}
            >
              {message.text}
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name *"
                required
              />
              {errors.name && <p className="error-text">{errors.name}</p>}
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber"
                pattern="[0-9]{10}"
                maxLength={10}
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Phone *"
                required
              />
              {errors.phoneNumber && <p className="error-text">{errors.phoneNumber}</p>}
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email *"
                required
              />
              {errors.email && <p className="error-text">{errors.email}</p>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                placeholder="Designation/Profession"
                required
              />
              {errors.designation && <p className="error-text">{errors.designation}</p>}
            </div>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
        <div className="profile">
          <img
            src="/assets/dr.nitika.jpg"  
            alt="Dr.Nikita Sobti"
            className="profile-image"
          />
          <h3 className="name">Dr. Nikita Sobti</h3>
          <p className="designation">"Obstetrician & Gynaecologist"</p>
        </div>
      </div>
    </div>
  );
};

export default App;
