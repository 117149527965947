import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./UI.css"; // Import the CSS file


const LogoutButton = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const confirmFormSelecton = () => {
    // Clear all data in local storage
   if(location.pathname !== "/form-selection"){
    navigate('/form-selection');
    // console.log(location.pathname)
   }else{
    return;
   }
  };
  const logout = () => {
      navigate('/');
  }

  return (
    <div>
      {location.pathname !== "/" && (
        <div className="logout-super">
          <div id="logout-container">
          <button id="logout-button" onClick={confirmFormSelecton}>
            Back To Selection
          </button>
          <button id="logout-button" onClick={logout}>
             Logout
          </button>
        </div>
        </div>
      )}
    </div>
  );
};

export default LogoutButton;
