// App.js
import React, {useState,useEffect} from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// Components Functions
import AuthPage from "./Pages/Authentication/Auth_Page";
import FormSelectionPage from "./Pages/Form_Selection/Form_Selection_Page";
import CustomerForm from "./Components/Customer_Form/Customer_Form.jsx";
import CustomerRevisit from "./Components/Revisit/customer_revisit-form.jsx";
import PhoneNumberVerification from "./Components/Phone_Number_Verification/Phone_Number_Verification";
import CustomerEvent from "./Pages/event/event_page.jsx";

import ChannelPartnerForm from "./Components/Channel_Partner_Form/Channel_Partner_Form";
import FormSelection from "./Components/UI/confirmSelection.js";
import Spinner from "./Components/UI/spinner.js";
import Brand from "./Components/UI/brand.js";


import Dashboard from "./Pages/DashBoard/dashboard.jsx";

import { Navigate } from 'react-router-dom';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  // Check if the user is authenticated when the component mounts
  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username === 'Admin Inframantra') {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);

  const PrivateRoute = ({ children }) => {

    const isAuthenticated = localStorage.getItem('username') === 'Admin Inframantra';

    return isAuthenticated ? children : <Navigate to="/" />;

  };

  const ProtectedRoute = ({children}) => {
    const isAuthenticated =localStorage.getItem("username");

    return isAuthenticated ? children : <Navigate to="/"/>
  }
  return (
    <Router>
      <FormSelection />
      <Brand />
      <Routes>
        {/* <Route exact path="/" element={<HomePage/>}/> */}
        <Route exact path="/" element={<AuthPage />} />
        <Route exact path="/form-selection" element={<ProtectedRoute><FormSelectionPage /></ProtectedRoute>} />
        <Route exact path="/customer-auth" element={<ProtectedRoute><CustomerForm /></ProtectedRoute>} />
        <Route exact path="channel-partner-auth" element={<ProtectedRoute><ChannelPartnerForm /></ProtectedRoute>} />
        <Route exact path="/revisit" element={<ProtectedRoute><CustomerRevisit/></ProtectedRoute>}></Route>
        <Route exact path="/event" element={<ProtectedRoute><CustomerEvent/></ProtectedRoute>}></Route>
        <Route path="*" element={<AuthPage />} />
        <Route exact path="/dashboard" element={ <PrivateRoute> <Dashboard /> </PrivateRoute>}/>
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        limit={2} // Set the limit to 3 toasts
        pauseOnHover
        theme="light"
      />
      <Spinner />
    </Router>
  );
};

export default App;
