import React from "react";
import { IoMdClose } from "react-icons/io";
import { MdOutlineDashboard } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { FaUser } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import { FaListOl } from "react-icons/fa";

const SideBar = ({isSidebarOpen, toggleSidebar, activeMenuItem, setActiveMenuItem}) =>{

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
      };

    return(
        <div className={`left_sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className='close_btn' onClick={toggleSidebar}>
          {" "}
          <IoMdClose />
        </button>
        <div className='logo '>
          <h2>
            <img src='/assets/infra-logo-b.png' alt='' />
          </h2>
        </div>
        <div className='main_content'>
          <div className='menu_items'>
            <div
              className={`menu_item ${
                activeMenuItem === "dashboard" && "active"
              }`}
              onClick={() => handleMenuItemClick("dashboard")}
            >
              <FaListOl style={{width:"30px",height:"30px"}}/>
              <p>{" "}Recent Visit's {" "}</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "calendar-vatika" && "active"
              }`}
              onClick={() => handleMenuItemClick("calendar-vatika")}
            >
              <FaBuilding style={{width:"30px",height:"30px"}}/>
              <p>Vatika Seven Element Visitor's</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "calendar-sovereign" && "active"
              }`}
              onClick={() => handleMenuItemClick("calendar-sovereign")}
            >
              <BiBuildingHouse style={{width:"30px",height:"30px"}}/>
              <p>Sovereign Park Visitor's</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "Channel-Partner" && "active"
              }`}
              onClick={() => handleMenuItemClick("Channel-Partner")}
            > 
            <FaRegHandshake style={{width:"30px",height:"30px"}}/>
              <p>CP Date</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "User" && "active"
              }`}
              onClick={() => handleMenuItemClick("Users")}
            > 
            <FaUser style={{width:"30px",height:"30px"}}/>
              <p>Sales List</p>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SideBar